import { Award,Shop,Truck,Notepad2,Send ,WalletMoney,MobileProgramming,ReceiptText,ShoppingBag,HomeTrendUp,NoteAdd,MoneySend,Book,TaskSquare} from 'iconsax-react';

import IntlMessages from "../../layout/components/lang/IntlMessages";

const pages = [
    {
        header: <IntlMessages id="sidebar-pages" />,
        
    },

   
    {
        id: "Secretaria",
        title: <IntlMessages id="secretaria" />,
        icon: <NoteAdd size={18} />,
        navLink: "/pages/secretaria",
        permissions: ["secretaria"],
        
    },
    {
        id: "midia",
        title: <IntlMessages id="midia" />,
        icon: <NoteAdd size={18} />,
        navLink: "/pages/midia",
        permissions: ["midia"],
        
    },
    {
        id: "cibe",
        title: <IntlMessages id="Cibe" />,
        icon: <NoteAdd size={18} />,
        navLink: "/pages/cibe",
        permissions: ["cibe"],
        
    },
    {
        id: "Phd",
        title: <IntlMessages id="Phd" />,
        icon: <NoteAdd size={18} />,
        navLink: "/pages/phd",
        permissions: ["phd"],
        
    },
    {
        id: "juca",
        title: <IntlMessages id="juca" />,
        icon: <NoteAdd size={18} />,
        navLink: "/pages/juca",
        permissions: ["juca"],
        
    },
    {
        id: "kids",
        title: <IntlMessages id="kids" />,
        icon: <NoteAdd size={18} />,
        navLink: "/pages/kids",
        permissions: ["kids"],
        
    },
    
    {
        id: "Administrativo",
        title: <IntlMessages id="Administrativo" />,
        icon: <HomeTrendUp size={18} />,
        navLink: "/pages/Administrativo",
        permissions: ["administrativo"]
    },
    
];

export default pages