import React from 'react'

import { Col, Row } from 'antd'

export default function Footer() {
  return (
    <Col span={24} className="hp-py-32">
      <p className="hp-mb-8 hp-p1-body hp-text-color-black-60">
        ADCN © 2025 
      </p>

    
    </Col>
  )
}
