import { Suspense, useEffect } from "react";
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../redux/customise/customiseActions";
import { Routes, Route, useNavigate } from "react-router-dom";



import { customRoutes } from "./routes";

import VerticalLayout from "../layout/VerticalLayout";
import HorizontalLayout from "../layout/HorizontalLayout";
import FullLayout from "../layout/FullLayout";
import HomePage from "../view/pages/errors/404/manutencao";
import Error404 from "../view/pages/errors/404";
import ProtectedRoute from "../configs/ProtectedRoute";
import Home from "../view/main/dashboard/ecommerce";


import ConditionalRoute from "../configs/ConditionalRoute";


export default function Router() {
    const customise = useSelector(state => state.customise);
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelector("body").classList.add(customise.theme);
        dispatch(theme(customise.theme));
    }, [customise.theme, dispatch]);

    useEffect(() => {
        document.querySelector("html").setAttribute("dir", customise.direction);
    }, [customise.direction]);

    const Layouts = { VerticalLayout, HorizontalLayout, FullLayout };
    const DefaultLayout = customise.layout;

    const DashboardRedirect = () => {
        const navigate = useNavigate();

        useEffect(() => {
            // Acessando as permissões diretamente do localStorage
            const userPermissions = JSON.parse(localStorage.getItem('userPermissions')) || [];
            console.log('User permissions loaded from localStorage:', userPermissions);

            if (userPermissions.length === 0) {
                console.log('No permissions found, waiting for permissions to be loaded...');
                // Aqui você pode optar por redirecionar para uma rota de erro ou carregamento
                return;
            }

            const firstPermission = userPermissions[0].toLowerCase();
            console.log('First user permission:', firstPermission);

            const getInitialRoute = () => {
                switch (firstPermission) {
                    case 'administrativo':
                        return "/pages/Administrativo";
                    case 'secretaria':
                        return "/pages/secretaria";
                    case 'midia':
                        return "/pages/midia";
                    case 'cibe':
                        return "/pages/cibe";
                    case 'phd':
                        return "/pages/phd";
                    case 'juca':
                        return "/pages/juca";
                    case 'kids':
                        return "/pages/kids";
                    default:
                        console.warn('Default route selected');
                        return "/default";
                }
            };

            const route = getInitialRoute();
            console.log('Redirecting to:', route);
            navigate(route);
        }, [navigate]);

        return null;
    };

    const renderRouteComponent = (route) => {
        const Layout = Layouts[route.layout] || Layouts[DefaultLayout];
        const RouteComponent = route.component;

        return (
            <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ type: "spring", duration: 0.5, delay: 0.5 }}
                    >
                        <RouteComponent />
                    </motion.div>
                </Suspense>
            </Layout>
        );
    };

    return (
        <Routes>
            {customRoutes.map((route) => (
                <Route
                    key={route.path}
                    path={route.path}
                    element={
                        route.isProtected ? (
                            <ProtectedRoute permissions={route.permissions || []}>
                                {renderRouteComponent(route)}
                            </ProtectedRoute>
                        ) : (
                            renderRouteComponent(route)
                        )
                    }
                />
            ))}

            <Route path="/" element={<HomePage />} />
            <Route path="/dashboard" element={
                <ProtectedRoute permissions={[]}>
                    <DashboardRedirect />
                </ProtectedRoute>
            } />



            <Route path="*" element={<Error404 />} />
        </Routes>
    );

};
