import { lazy } from "react";

const PagesRoutes = [






  //Registar

  //{
  //  path: "/register",
  //  component: lazy(() => import("../../view/pages/registrar/index")),
  //  layout: "FullLayout",

  //},

  // {
  //   path: "/login2",
  //   component: lazy(() => import("../../view/pages/registrar/login")),
  //   layout: "FullLayout",

  //  },



  // ADM
  {
    path: "/pages/Administrativo",
    component: lazy(() => import("../../view/pages/adm")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["administrativo"]
  },
 //cibe
  {
    path: "/pages/cibe",
    component: lazy(() => import("../../view/pages/cibe/")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["cibe"],
  },
  {
    path: "/cibeg/adm",
    component: lazy(() => import("../../view/pages/cibe/cibeg")),
    layout: "VerticalLayout",
    isProtected: true,
    permissions: ["cibe"],
  },




  // Eroo
  {
    path: "/pages/error-404",
    component: lazy(() => import("../../view/pages/errors/404")),
    layout: "FullLayout",
  },
  {
    path: "/pages/manutencao",
    component: lazy(() => import("../../view/pages/errors/404/manutencao")),
    layout: "FullLayout",
  },


  // Login

  {
    path: "/login",
    component: lazy(() => import("../../view/pages/authentication-modern/login")),
    layout: "FullLayout",
  },

  {
    path: "/home",
    component: lazy(() => import("../../view/pages/home")),
    layout: "FullLayout",
  },

  {
    path: "/cibeg",
    component: lazy(() => import("../../view/pages/cibeg")),
    layout: "FullLayout",
  },

  {
    path: "/cibeg/inscricao",
    component: lazy(() => import("../../view/pages/cibeg/inscrição")),
    layout: "FullLayout",
  },
  {
    path: "/cibeg/confirmar-pagamento",
    component: lazy(() => import("../../view/pages/cibeg/confirmação")),
    layout: "FullLayout",
  },
  {
    path: "/cibeg/pagamento",
    component: lazy(() => import("../../view/pages/cibeg/pagamento")),
    layout: "FullLayout",
  },


  //Usuarios

  {
    path: "/personel-information",
    component: lazy(() => import("../../view/pages/profile/personel-information")),
    layout: "VerticalLayout",
    isProtected: true,
  },


];

export default PagesRoutes;