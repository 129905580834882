import React from "react";
import { Link } from "react-router-dom";

import { Row, Col, Button } from "antd";

import Line from "../line";
import Header from "../header";
import Footer from "../footer";

export default function MaintenancePage() {
  return (
    <Row className="hp-text-center hp-overflow-hidden">
      <Line />

      <Header />

      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100" align="middle" justify="center">
          <Col>
            

            <h2 className="h1 hp-mb-16">Estamos em manutenção</h2>

            <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
              Nossa página está passando por atualizações. Por favor, volte mais tarde.
            </p>

           
          </Col>
        </Row>
      </Col>

      <Footer />
    </Row>
  );
}
